import Loading from '@/components/shared/Loading';
import http from '@/configs/http';
import { ENDPOINTCONSTANTS } from '@/constants/EndPointConstants';
import { DetailsUser } from '@/types/Auth';
import { Countries, District, Province, Ward } from '@/types/Countries';
import { formatMoney, formatPoint } from '@/utils/formatMoney';
import { getCustomerRankName, getCustomerRole } from '@/utils/formatUser';
import { Form, Input, Modal, PaginationProps, Radio, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import InviteCode from './components/InviteCode';
import { TableReferals } from './components/TableReferals';
import { TListOrder } from '@/types/Coupon';
import ManageCoupon from '../components/ManageCoupon';
import WithdrawMoneyTable from '../../WithdrawMoney/components/SearchAndTable';
import funcUtils from '@/configs/funcUtil';
import { DataType } from '../../WithdrawMoney';

export default function DetailUser() {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState<number>(1);
    const [size, setSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(1);
    const [data, setData] = useState<DataType[]>([]);
    const onChange: PaginationProps['onChange'] = (page) => {
        console.log(page);
        setPage(page);
    };
    const changeStatus = async (id: any, status: string) => {
        setLoading(true);
        try {
            const response = await http.put(`${ENDPOINTCONSTANTS.WITHDRAW_MONEY}`, {
                id: id,
                status: status,
            });
            if (response?.status === 200) {
                funcUtils.notify(`Bạn đã ${status === 'done' ? 'duyệt' : 'hủy'} thành công`, 'success');
                getApi();
            }
        } catch (e) {
            setLoading(false);
            funcUtils.notify('Thay đổi trạng thái thất bại', 'error');
        }
    };
    useEffect(() => {
        getApi();
    }, [page]);
    const getApi = async () => {
        setLoading(true);
        try {
            const response = await http.get(`${ENDPOINTCONSTANTS.WITHDRAW_MONEY}?page=${page}&size=${size}`);
            setTotal(response.data?.totalPage);
            setData(response.data?.requestWithdraws);
            if (response.status === 200) {
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
            funcUtils.notify('Lấy dữ liệu thất bại', 'error');
        }
    };
    const [form] = Form.useForm();

    const [isBlockActive, setIsBlockActive] = React.useState(false);
    const [isLoad, setIsLoad] = React.useState(false);

    const [isDetail, setIsDetail] = React.useState<DetailsUser>();

    const handleBlockActive = async () => {
        try {
            toast.success('Cập nhật thành công');
        } catch (error) {
            console.log(error);
        } finally {
            setIsBlockActive(!isBlockActive);
        }
    };

    const { id } = useParams();

    const [countries, setCountries] = useState<Countries[]>([]);
    const [provinces, setProvinces] = useState<Province[]>([]);
    const [districts, setDistricts] = useState<District[]>([]);
    const [wards, setWards] = useState<Ward[]>([]);

    const [listOrder, setListOrder] = useState<TListOrder>();

    const [isCountry, setIsCountry] = useState<Countries | null>(null);
    const [isProvince, setIsProvince] = useState<Province | null>(null);
    const [isDistrict, setIsDistrict] = useState<District | null>(null);
    const [isWard, setIsWard] = useState<Ward | null>(null);

    const handleGetDetailUser = async () => {
        setIsLoad(true);
        try {
            const response = await http.get(`user/${id}`);
            const data = response.data;

            const rankName = getCustomerRankName(data?.rank);
            const roleName = getCustomerRole(data?.role);

            form.setFieldsValue({
                firstName: data?.name,
                lastName: data?.name,
                email: data?.email,
                phone: data?.phoneNumber,
                // birthday: new Date(data?.birthday).toISOString().split('T')[0],
                gender: data?.gender,
                address: data?.address1,
                accumulatedRefPoint: formatMoney(data?.accumulatedRefPoint <= 0 ? 0 : data?.accumulatedRefPoint),
                point: formatPoint(data?.point),
                rank: rankName,
                cumulativeTovRecorded: formatMoney(data?.cumulativeTovRecorded <= 0 ? 0 : data?.cumulativeTovRecorded),
                accumulatedOrderPoint: formatMoney(data?.accumulatedOrderPoint <= 0 ? 0 : data?.accumulatedOrderPoint),
                role: roleName,
                rankExpirationTime: data?.rankExpirationTime
                    ? new Date(data?.rankExpirationTime).toISOString().split('T')[0]
                    : '------',

                withdrawPending: formatMoney(data?.withdrawPending <= 0 ? 0 : data?.withdrawPending),
                withdrawSuccess: formatMoney(data?.withdrawSuccess <= 0 ? 0 : data?.withdrawSuccess),
            });

            // Để đem truyền cho invitecode
            setIsDetail(data);
            // Gọi hàm handleGetCountries sau khi lấy dữ liệu chi tiết

            const code = {
                country_code: data?.addresses?.[0]?.country_code,
                province_code: data?.addresses?.[0]?.province_code,
                district_code: data?.addresses?.[0]?.district_code,
                ward_code: data?.addresses?.[0]?.ward_code,
            };

            handleGetCountries(code);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoad(false);
        }
    };

    const handleGetCountries = async (code: any) => {
        try {
            const response = await http.get(ENDPOINTCONSTANTS.COUNTRIES);

            // Để dropdown shơ xuống bên dưới
            setCountries(response.data.countries);

            // Tìm và thiết lập giá trị mặc định cho select quốc gia
            const defaultCountry = response.data.countries.find(
                (country: any) => country.code === code?.country_code?.toUpperCase(),
            );

            if (defaultCountry) {
                setIsCountry(defaultCountry);
                form.setFieldsValue({ country_code: defaultCountry.id });
                // Gọi hàm handleGetProvinces để lấy danh sách tỉnh/thành phố
                handleGetProvinces(defaultCountry.id, code);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleGetProvinces = async (countryId: number, code?: any) => {
        try {
            const response = await http.get(`${ENDPOINTCONSTANTS.COUNTRIES}/${countryId}/provinces`);
            setProvinces(response.data.provinces);

            // // Tìm và thiết lập giá trị mặc định cho select tỉnh/thành phố
            if (code.province_code) {
                await response.data.provinces.forEach((province: Province) => {
                    if (province.code === code.province_code) {
                        setIsProvince(province);
                        form.setFieldsValue({
                            province_code: province.id,
                        });
                        // Gọi hàm handleGetDistricts để lấy danh sách quận/huyện
                        handleGetDistricts(province.id, code);
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleGetDistricts = async (provinceId: number, code?: any) => {
        try {
            const response = await http.get(`/information/provinces/${provinceId}/districts`);
            setDistricts(response.data.districts);

            if (code.district_code) {
                await response.data.districts.forEach((district: District) => {
                    if (district.code === code.district_code) {
                        setIsDistrict(district);
                        form.setFieldsValue({
                            district_code: district.id,
                        });
                        // Gọi hàm handleGetWards để lấy danh sách phường/xã
                        handleGetWards(district.id, code);
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleGetWards = async (districtId: number, code?: any) => {
        try {
            const response = await http.get(`/information/districts/${districtId}/wards`);
            setWards(response.data.wards);

            if (code.ward_code) {
                await response.data.wards.forEach((ward: Ward) => {
                    if (ward.code === code.ward_code) {
                        setIsWard(ward);
                        form.setFieldsValue({
                            ward_code: ward.id,
                        });
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        handleGetDetailUser();
    }, [id]);

    const handleSubmit = async () => {
        setIsLoad(true);
        try {
            const values = await form.validateFields();

            const dataUpdate = {
                firstName: values.firstName,
                lastName: values.lastName,
                phone: values.phone,
                birthday: values.birthday
                    ? new Date(values.birthday).toISOString().split('T')[0]
                    : new Date().toISOString().split('T')[0],
                gender: values.gender,
                addresses: [
                    {
                        country: isCountry?.name,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        phone: values.phone,
                        address1: values.address,
                        countryCode: isCountry?.code,
                        provinceCode: isProvince?.code,
                        districtCode: isDistrict?.code,
                        wardCode: isWard?.code,
                    },
                ],
                defaultAddress: {
                    country: isCountry?.name,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    phone: values.phone,
                    address1: values.address,
                    countryCode: isCountry?.code,
                    provinceCode: isProvince?.code,
                    districtCode: isDistrict?.code,
                    wardCode: isWard?.code,
                },
            };

            await http.put(`user/${id}`, dataUpdate);

            toast.success('Cập nhật thành công');
        } catch (error) {
            console.log(error);
            toast.error('Cập nhật thất bại');
        } finally {
            setIsLoad(false);
        }
    };

    if (isLoad) {
        return <Loading />;
    }

    return (
        <div>
            <Form
                form={form}
                name="validateOnly"
                layout="vertical"
                className="w-full flex flex-col gap-5"
                autoComplete="off"
            >
                <Modal
                    title="Chặn hoạt động"
                    open={isBlockActive}
                    onOk={handleBlockActive}
                    onCancel={() => setIsBlockActive(false)}
                    okText={'Vô hiệu hóa'}
                    cancelText="Hủy"
                >
                    <div className="w-full px-6 py-8 flex justify-center items-center bg-gray-200 rounded-lg">
                        <h1 className="font-semibold text-2xl text-center text-red-500">
                            Bạn đồng ý chặn hoạt động của tài khoản này
                        </h1>
                    </div>
                </Modal>

                <h1 className="text-lg">Chi tiết khách hàng</h1>
                <div className="flex gap-2 items-center justify-end">
                    {/* <Button className={` bg-red-500 text-white h-[2.5rem]`}>Vô hiệu hóa</Button> */}
                    {/* <Button className="h-[2.5rem] bg-primary text-white" type="primary" onClick={handleSubmit}>
                    Lưu
                </Button> */}
                </div>
                <div className="flex gap-5">
                    <div className="flex-1 flex flex-col gap-5">
                        <div className="bg-white p-5 rounded-lg">
                            <div className="w-full items-center flex justify-between">
                                <h1 className="font-semibold">Thông tin chung</h1>
                            </div>
                            <hr className="my-5" />
                            <div className="w-full grid grid-cols-2 gap-x-10">
                                {/* <Form.Item
                                name="lastName"
                                label="Họ"
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Vui lòng nhập họ',
                                //     },
                                // ]}
                            >
                                <Input
                                    size="small"
                                    placeholder="Nhập họ"
                                    className="h-[2.75rem] disabled:bg-white disabled:text-black "
                                    disabled
                                />
                            </Form.Item> */}
                                <Form.Item
                                    name="firstName"
                                    label="Tên"
                                    // rules={[{ required: true, message: 'Vui lòng nhập tên' }]}
                                >
                                    <Input
                                        size="small"
                                        placeholder="Nhập tên"
                                        className="h-[2.75rem] disabled:bg-white disabled:text-black "
                                        disabled
                                    />
                                </Form.Item>
                                {/* <Form.Item name="email" label="Địa chỉ email">
                                <Input size="small" placeholder={'Vui lòng nhập email'} className="h-[2.75rem]" />
                            </Form.Item> */}
                                <Form.Item
                                    name="phone"
                                    label="Số điện thoại"
                                    // rules={[{ required: true, message: 'Vui lòng nhập số điện thoại' }]}
                                >
                                    <Input
                                        size="small"
                                        placeholder="Nhập số điện thoại"
                                        className="h-[2.75rem] disabled:bg-white disabled:text-black "
                                        disabled
                                    />
                                </Form.Item>
                                {/* <Form.Item
                                name="birthday"
                                label="Chọn ngày sinh"
                                rules={[{ required: true, message: 'Vui lòng chọn ngày sinh' }]}
                            >
                                <Input size="small" datatype="date" type="date" className="h-[2.75rem]" />
                            </Form.Item> */}
                                <Form.Item name="cumulativeTovRecorded" label="Doanh thu thực mua">
                                    <Input
                                        size="small"
                                        placeholder="Doanh thu thực mua"
                                        className="h-[2.75rem] disabled:bg-white disabled:text-black"
                                        disabled
                                    />
                                </Form.Item>
                                <Form.Item name="rank" label="Hạng khách hàng">
                                    <Input
                                        size="small"
                                        placeholder="Hạng khách hàng"
                                        className="h-[2.75rem] disabled:bg-white disabled:text-black"
                                        disabled
                                    />
                                </Form.Item>
                                <Form.Item name="accumulatedRefPoint" label="Doanh thu Referrals">
                                    <Input
                                        size="small"
                                        className="h-[2.75rem] disabled:bg-white disabled:text-black"
                                        disabled
                                    />
                                </Form.Item>
                                <Form.Item name="withdrawPending" label="Cashback pending">
                                    <Input
                                        size="small"
                                        className="h-[2.75rem] disabled:bg-white disabled:text-black"
                                        disabled
                                    />
                                </Form.Item>
                                <Form.Item name="withdrawSuccess" label="Cashback đã rút">
                                    <Input
                                        size="small"
                                        className="h-[2.75rem] disabled:bg-white disabled:text-black"
                                        disabled
                                    />
                                </Form.Item>
                            </div>
                            <div className="w-full grid grid-cols-2 gap-x-10">
                                <Form.Item name="point" label="Cashback">
                                    <Input
                                        size="small"
                                        placeholder="Cashback"
                                        className="h-[2.75rem] disabled:bg-white disabled:text-black"
                                        disabled
                                    />
                                </Form.Item>

                                <Form.Item name="accumulatedOrderPoint" label="Doanh thu tích lỹ 12 tháng">
                                    <Input
                                        size="small"
                                        placeholder="Doanh thu tích lỹ 12 tháng"
                                        className="h-[2.75rem] disabled:bg-white disabled:text-black"
                                        disabled
                                    />
                                </Form.Item>

                                <Form.Item name="role" label="Loại khách hàng">
                                    <Input
                                        size="small"
                                        placeholder="Loại khách hàng"
                                        className="h-[2.75rem] disabled:bg-white disabled:text-black"
                                        disabled
                                    />
                                </Form.Item>
                                <Form.Item name="rankExpirationTime" label="Ngày hết hạn của rank">
                                    <Input
                                        size="small"
                                        placeholder="Ngày hết hạn của rank disabled:bg-white"
                                        className="h-[2.75rem] disabled:bg-white disabled:text-black"
                                        disabled
                                    />
                                </Form.Item>
                                <Form.Item label="Giới tính" name="gender">
                                    <Radio.Group className="disabled:bg-white " disabled>
                                        <Radio value={0}> Nam </Radio>
                                        <Radio value={1}> Nữ </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <section className="flex flex-col gap-5">
                        <div className=" h-auto flex-col gap-5 flex">
                            <div className="bg-white p-5 rounded-lg ">
                                <h1 className="font-semibold">Địa chỉ</h1>
                                <hr className="my-5" />
                                <div className="w-full grid grid-cols-1 gap-x-10">
                                    <Form.Item
                                        name="address"
                                        label="Địa chỉ"
                                        // rules={[{ required: true, message: 'Vui lòng nhập địa chỉ' }]}
                                    >
                                        <Input
                                            size="small"
                                            placeholder="Địa chỉ"
                                            className="h-[2.75rem] disabled:bg-white disabled:text-black"
                                            disabled
                                        />
                                    </Form.Item>
                                    {/* <Form.Item label="Quốc gia" name={'country_code'}>
                                <Select
                                    disabled
                                    className="h-[2.75rem] disabled:bg-white disabled:text-black"
                                    placeholder={isCountry?.id ? isCountry?.name : 'Chọn quốc gia'}
                                    onChange={(value) => {
                                        handleGetProvinces(value);
                                        setIsCountry(countries.find((country) => country.id === value) || null);
                                        setIsProvince(null);
                                        form.setFieldsValue({ province_code: null });
                                        setIsDistrict(null);
                                        form.setFieldsValue({ district_code: null });
                                        setIsWard(null);
                                        form.setFieldsValue({ ward_code: null });
                                    }}
                                    // defaultValue={241}
                                >
                                    {countries.map((country) => (
                                        <Select.Option key={country.id} value={country.id}>
                                            {country.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Tỉnh/Thành phố" name={'province_code'}>
                                <Select
                                    disabled
                                    placeholder={isProvince?.id ? isProvince?.name : 'Chọn tỉnh/ thành phố'}
                                    className="h-[2.75rem] disabled:bg-white disabled:text-black"
                                    onChange={(value) => {
                                        handleGetDistricts(value);
                                        setIsProvince(provinces.find((province) => province.id === value) || null);
                                        setIsDistrict(null);
                                        form.setFieldsValue({ district_code: null });
                                        setIsWard(null);
                                        form.setFieldsValue({ ward_code: null });
                                    }}
                                >
                                    {provinces.map((province) => (
                                        <Select.Option key={province.id} value={province.id}>
                                            {province.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Quận/Huyện" name={'district_code'}>
                                <Select
                                    disabled
                                    placeholder={isDistrict?.id ? isDistrict?.name : 'Chọn quận/ huyện'}
                                    className="h-[2.75rem] ant-select-disabled:bg-white ant-select-disabled:text-black"
                                    onChange={(value) => {
                                        handleGetWards(value);
                                        setIsDistrict(districts.find((district) => district.id === value) || null);
                                        setIsWard(null);
                                        form.setFieldsValue({ ward_code: null });
                                    }}
                                >
                                    {districts.map((district) => (
                                        <Select.Option key={district.id} value={district.id}>
                                            {district.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Phường/ Xã" name={'ward_code'}>
                                <Select
                                    disabled
                                    placeholder={isWard?.id ? isWard?.name : 'Chọn phường/ xã'}
                                    className="h-[2.75rem] disabled:bg-white disabled:text-black"
                                    onChange={(value) => {
                                        setIsWard(wards.find((ward) => ward.id === value) || null);
                                    }}
                                >
                                    {wards.map((ward) => (
                                        <Select.Option key={ward.id} value={ward.id}>
                                            {ward.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item> */}
                                </div>
                                <hr className="my-5" />
                                {/* <Form.Item label="Loại khách hàng">
                            <Select placeholder="Chọn loại khách hàng" className="h-[2.75rem]">
                                <Select.Option value="1">Đối tác phát triển hạng A</Select.Option>
                                <Select.Option value="2">Đối tác phát triển hạng B</Select.Option>
                                <Select.Option value="3">Khách hàng thông thường</Select.Option>
                            </Select>
                        </Form.Item> */}
                            </div>
                            {/* <div className="bg-white p-5 rounded-lg h-auto w-full ">
                        <h1 className="font-semibold">Thông tin bổ sung</h1>
                        <hr className="my-5" />
                        <div className="w-full grid grid-cols-2 gap-x-10">
                            <Form.Item name="accumulatedOrderPoint" label="Giá trị đơn hàng tích luỹ">
                                <Input size="small" disabled className="h-[2.75rem]" />
                            </Form.Item>
                            <Form.Item name="rankPoint" label="Điểm hạng">
                                <Input size="small" disabled className="h-[2.75rem]" />
                            </Form.Item>
                            <Form.Item name="MembershipClass" label="Hạng thành viên">
                                <Input size="small" defaultValue={'#12'} disabled className="h-[2.75rem]" />
                            </Form.Item>
                            <Form.Item name="Revenue" label="Doanh thu">
                                ￼
                                <Input size="small" defaultValue={'$5.00.00'} disabled className="h-[2.75rem]" />
                            </Form.Item>
                        </div>
                    </div> */}
                            {/* Chỉ hiện nếu loại khách hàng là Đối tác khách hàng hạng A / Khác hàng hạng B  */}
                        </div>
                    </section>
                </div>
                <InviteCode user={isDetail} />
                {/* <Tabs
                            defaultActiveKey="1"
                            items={[
                                {
                                    label: 'Mã mời',
                                    key: '1',
                                    children: <InviteCode user={isDetail} />,
                                },
                                {
                                    label: 'Đơn hàng',
                                    key: '2',
                                    children: <Order user={isDetail} />,
                                },
                            ]}
                        /> */}
            </Form>
            <h1 className="text-lg mt-6">Quản lý mã mời</h1>
            <ManageCoupon valueSearch="" />
            <h1 className="text-lg mb-4">Quản lý thanh toán</h1>
            <WithdrawMoneyTable />
        </div>
    );
}
