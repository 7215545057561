import { BaseQueryPagination, formatMoney } from '@/utils';
import { TableColumnsType } from 'antd';

export interface QueryCouponPagination extends BaseQueryPagination {}

export interface MetaType {
    page: string;
    size: string;
    totalPage: number;
}

export interface DataType {
    key: React.Key;
    id: string;
    couponHaravanCode: string;
    status: JSX.Element;
    usedByName: string;
    // user: string;
    haravanOrderId: JSX.Element;
    paymentStatus: string;
    totalPrice: number;
    // expectedCashbackref: number;
    cashbackref: number;
}

export const columns: TableColumnsType<DataType> = [
    {
        title: 'Mã',
        dataIndex: 'couponHaravanCode',
        render: (text, record) => <span>{record.couponHaravanCode}</span>,
    },
    {
        title: 'Trạng thái sử dụng',
        dataIndex: 'status',
        render: (text, record) => <span>{record.status}</span>,
    },
    {
        title: 'Người sử dụng',
        dataIndex: 'usedBy',
        render: (text, record) => <span>{record.usedByName}</span>,
    },
    {
        title: 'Mã ĐH Haravan',
        dataIndex: 'haravanOrderId',
        render: (text, record) => <span>{record.haravanOrderId}</span>,
    },
    {
        title: 'Trạng thái thanh toán',
        dataIndex: 'paymentStatus',
        render: (text, record) => <span>{record.paymentStatus}</span>,
    },
    {
        title: 'Tổng đơn hàng',
        dataIndex: 'totalPrice',
        render: (text, record) => <span>{formatMoney(record.totalPrice)}</span>,
    },
    {
        title: 'Cashbackref',
        dataIndex: 'cashbackref',
        render: (text, record) => <span>{formatMoney(record.cashbackref)}</span>,
    },
];
