import { PATHCONSTANTS } from '@/constants/PathConstants';
import { CopyOutlined } from '@ant-design/icons';
import { Button, PaginationProps, TableColumnsType, Tag } from 'antd';
import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import funcUtils from '@/configs/funcUtil';
import http from '@/configs/http';
import { ENDPOINTCONSTANTS } from '@/constants/EndPointConstants';
import { formatDate } from '@/utils/formatDate';
import { formatMoney } from '@/utils';
import WithdrawMoneyTable from './components/SearchAndTable';
export interface DataType {
    key: React.Key;
    id: string;
    bankName: string;
    bankNumber: string;
    amount: number;
    status: string;
    createdDate: string;
}

const WithdrawMoney = () => {
    return (
        <main className="w-full flex flex-col gap-5">
            <section className="bg-white rounded-lg flex items-center justify-between py-5 px-5">
                <div className="text-xl font-semibold flex flex-1 items-center gap-5">
                    <p>{PATHCONSTANTS.WITHDRAW_MONEY.TITLE}</p>
                </div>
                <div className="text-lg flex gap-5 duration-300">
                    {/* <Dropdown menu={{ items }} trigger={['click']}>
                        <a onClick={(e) => e.preventDefault()}>
                            <Space className="bg-gray-200 flex justify-center items-center h-[2.4rem] w-[2.4rem] rounded-lg">
                                <EllipsisOutlined
                                    style={{ fontSize: '1.5rem' }}
                                    className="translate-y-[0.15rem] text-gray-800"
                                />
                            </Space>
                        </a>
                    </Dropdown> */}
                    {/* <Link to={PATHCONSTANTS.USER_MANAGER_NEW.PATH}>
                      <Button type="primary" className="bg-primary h-[2.4rem] flex items-center">
                          <PlusCircleOutlined />
                          {PATHCONSTANTS.USER_MANAGER_NEW.TITLE}
                      </Button>
                  </Link> */}
                </div>
            </section>
            <WithdrawMoneyTable />
        </main>
    );
};

export default WithdrawMoney;
