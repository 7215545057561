import { Input, Table, Pagination, PaginationProps, Button, Tag, TableColumnsType } from 'antd';
import { DataType } from '..';
import { useEffect, useState } from 'react';
import { ENDPOINTCONSTANTS } from '@/constants/EndPointConstants';
import http from '@/configs/http';
import funcUtils from '@/configs/funcUtil';
import { formatMoney } from '@/utils';
import { formatDate } from '@/utils/formatDate';
import { CopyOutlined } from '@ant-design/icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useParams, useSearchParams } from 'react-router-dom';

const { Search } = Input;

interface WithdrawMoneyTable {}

const WithdrawMoneyTable = () => {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState<number>(1);
    const [size, setSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(1);
    const [data, setData] = useState<DataType[]>([]);
    const { id } = useParams<{ id: string }>();
    const onChange: PaginationProps['onChange'] = (page) => {
        console.log(page);
        setPage(page);
    };
    const changeStatus = async (id: any, status: string) => {
        setLoading(true);
        try {
            const response = await http.put(`${ENDPOINTCONSTANTS.WITHDRAW_MONEY}`, {
                id: id,
                status: status,
            });
            if (response?.status === 200) {
                funcUtils.notify(`Bạn đã ${status === 'done' ? 'duyệt' : 'hủy'} thành công`, 'success');
                getApi();
            }
        } catch (e) {
            setLoading(false);
            funcUtils.notify('Thay đổi trạng thái thất bại', 'error');
        }
    };
    useEffect(() => {
        getApi();
    }, [page]);
    const getApi = async () => {
        setLoading(true);
        try {
            const response = await http.get(`${ENDPOINTCONSTANTS.WITHDRAW_MONEY}`, {
                params: {
                    page: page,
                    size: size,
                    ownerId: id,
                },
            });
            setTotal(response.data?.totalPage);
            setData(response.data?.requestWithdraws);
            if (response.status === 200) {
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
            funcUtils.notify('Lấy dữ liệu thất bại', 'error');
        }
    };

    const columns: TableColumnsType<DataType> = [
        {
            title: 'Tên người rút',
            key: 'id',
            dataIndex: 'user',
            render: (user: any) => <p>{user?.name}</p>,
        },
        {
            title: 'Số điện thoại',
            key: 'id',
            dataIndex: 'user',
            render: (user: any) => <p>{user.phoneNumber}</p>,
        },
        {
            title: 'Tên ngân hàng',
            key: 'id',
            dataIndex: 'bankName',
        },
        {
            title: 'Số ngân hàng',
            key: 'id',
            dataIndex: 'bankNumber',
            render: (stk: number) => (
                <div className="flex gap-[10px]">
                    <div>{stk}</div>
                    <div>
                        {' '}
                        <CopyToClipboard text={stk.toString()}>
                            <CopyOutlined
                                onClick={() => {
                                    funcUtils.notify('Đã copy ', 'success');
                                }}
                                className="text-primary text-[16px] hover:scale-110 duration-200"
                            />
                        </CopyToClipboard>
                    </div>
                </div>
            ),
        },
        {
            title: 'Số tiền',
            key: 'id',
            dataIndex: 'amount',
            render: (amount: any) => {
                return formatMoney(amount);
            },
        },
        {
            title: 'Số dư còn lại',
            key: 'id',
            dataIndex: 'remainAmount',
            render: (amount: any) => {
                return formatMoney(amount);
            },
        },
        {
            title: 'Ngày tạo',
            key: 'id',
            dataIndex: 'createdDate',
            render: (date: any) => {
                return formatDate(date);
            },
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'key',
            render: (status: string) => {
                return status === 'pending' ? (
                    <Tag color="gold">Chờ duyệt</Tag>
                ) : status === 'done' ? (
                    <Tag color="success">Đã duyệt</Tag>
                ) : (
                    <Tag color="red">Đã hủy</Tag>
                );
            },
        },
        {
            title: 'Thao tác',
            render: (data: any) => (
                <div>
                    {data?.status === 'pending' ? (
                        <div>
                            <Button
                                onClick={() => {
                                    changeStatus(data?.id, 'done');
                                }}
                                className="bg-primary text-white"
                            >
                                Duyệt
                            </Button>
                            <Button
                                onClick={() => {
                                    changeStatus(data?.id, 'rejected');
                                }}
                                className="bg-red-500 text-white"
                            >
                                Hủy
                            </Button>
                        </div>
                    ) : data?.status === 'done' ? (
                        <Button
                            onClick={() => {
                                changeStatus(data?.id, 'rejected');
                            }}
                            className="bg-red-500 text-white"
                        >
                            Hủy
                        </Button>
                    ) : (
                        <Button
                            onClick={() => {
                                changeStatus(data?.id, 'done');
                            }}
                            className="bg-primary text-white"
                        >
                            Duyệt
                        </Button>
                    )}
                </div>
            ),
        },
    ];

    return (
        <section className="bg-white rounded-lg py-5 px-5">
            <div className="w-full flex items-center gap-5">
                <Search placeholder="Tìm kiếm" enterButton="Tìm kiếm" className="h-[2.75rem] w-full" size="middle" />
            </div>
            <Table loading={loading} columns={columns} dataSource={data} pagination={false} />
            <Pagination
                className="flex justify-end pt-[10px]"
                current={page}
                onChange={onChange}
                total={total * size}
            />
        </section>
    );
};

export default WithdrawMoneyTable;
