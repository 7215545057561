import http from '@/configs/http';
import { ENDPOINTCONSTANTS } from '@/constants/EndPointConstants';
import { ItemCouponRef } from '@/types/PartnerInVitationCode';
import { formatTime } from '@/utils/formatDate';
import { Table } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { DataType, MetaType, QueryUserPagination, columnsPartnerInvitationCode } from './Constants';
import CreateCouponRef from './components/CreateCouponRef';
import DeleteCouponRef from './components/DeleteCouponRef';
import { Link } from 'react-router-dom';

export default function PartnerInvitationCode() {
    const [isDataCouponRef, setIsDataCouponRef] = useState<DataType[]>([]);
    const [isMeta, setIsMeta] = useState<MetaType | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const [query, setQuery] = useState<QueryUserPagination>({
        role: 'partnerA',
        type: 'partner',
    });

    const handleGetAllPartnerInvitationCode = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await http.get(ENDPOINTCONSTANTS.COUPON_REF, {
                params: query,
            });

            const data = response.data.items.map((item: ItemCouponRef) => {
                return {
                    key: item.id,
                    inviteCode: item?.couponHaravanCode,
                    timeStart: formatTime(item.startDate),
                    timeEnd: item.endDate ? formatTime(item.endDate) : '------',
                    used: item.used,
                    usedBy: item.owner ? (
                        <Link to={`/manager/user/${item.owner.id}`}>{item.owner.useByName}</Link>
                    ) : (
                        '------'
                    ),
                    note: item.note ? item.note : '------',
                    action: (
                        <DeleteCouponRef
                            id={item.id}
                            setLoading={setIsLoading}
                            refetchData={handleGetAllPartnerInvitationCode}
                        />
                    ),
                };
            });

            console.log(data);

            setIsDataCouponRef(data);
            setIsMeta(response.data.meta);
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsLoading(false);
        }
    }, [query]);

    useEffect(() => {
        handleGetAllPartnerInvitationCode();
    }, [handleGetAllPartnerInvitationCode]);

    return (
        <div className="flex-1 bg-white rounded-lg flex flex-col gap-5 p-5">
            <div className="w-full flex justify-end">
                <CreateCouponRef setLoading={setIsLoading} refetchData={handleGetAllPartnerInvitationCode} />
            </div>
            <Table
                columns={columnsPartnerInvitationCode}
                dataSource={isDataCouponRef}
                loading={isLoading}
                pagination={{
                    total: isMeta?.totalItems,
                    current: isMeta?.currentPage,
                    pageSize: isMeta?.itemsPerPage,
                    showSizeChanger: false,
                }}
                onChange={(pagination) => {
                    setQuery({
                        ...query,
                        page: pagination.current,
                    });
                }}
            />
        </div>
    );
}
