import http from '@/configs/http';
import { ENDPOINTCONSTANTS } from '@/constants/EndPointConstants';
import { ItemCoupon, ItemOrder } from '@/types/Coupon';
import { useCallback, useEffect, useState } from 'react';
import { columns, DataType, MetaType, QueryCouponPagination } from './constant';
import { toast } from 'react-toastify';
import { PageSize } from '@/constants/Common';
import { Table } from 'antd';
import { formatDate } from '@/utils/formatDate';
import Paragraph from 'antd/es/typography/Paragraph';
import { useParams } from 'react-router-dom';

type Props = {
    valueSearch: string;
};

export default function ManageCoupon({ valueSearch }: Props) {
    const [listCoupons, setListCoupons] = useState<ItemCoupon[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isMeta, setIsMeta] = useState<MetaType | null>(null);
    const { id } = useParams<{ id: string }>();

    const [query, setQuery] = useState<QueryCouponPagination>({
        query: valueSearch,
        limit: PageSize.medium,
        page: 1,
    });

    const mockCoupons: DataType[] = listCoupons.map((item) => {
        return {
            key: item.id,
            id: item.id,
            couponHaravanCode: item.couponHaravanCode,
            status:
                item.usedCount > 0 ? (
                    <p className="text-green-500">Đã sử dụng</p>
                ) : (
                    <p className="text-yellow-500">Chưa sử dụng</p>
                ),
            usedByName: item.usedByName ? item.usedByName : '------',
            startTime: formatDate(item.startDate),
            endTime: item.endDate ? formatDate(item.endDate) : '------',
            haravanOrderId: (
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline hover:underline"
                    href={`https://jemmiavn.myharavan.com/admin/orders/${item.order[0]?.haravanOrderId}`}
                >
                    {item.order[0]?.haravanOrderId}
                </a>
            ),
            paymentStatus: item.order[0]?.paymentStatus,
            totalPrice: item.order[0]?.totalPrice || 0,
            cashbackref: item.order[0]?.cashBackRef || 0,
        };
    });

    const getAllCoupons = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await http.get(ENDPOINTCONSTANTS.COUPON_REF, {
                params: { ...query, isUsed: true, ownerId: id },
            });

            setIsMeta({
                page: response.data.page,
                size: response.data.size,
                totalPage: response.data.totalPage,
            });

            setListCoupons(response.data.items);
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    }, [query]);

    useEffect(() => {
        getAllCoupons();
    }, []);

    useEffect(() => {
        setQuery((prevQuery) => ({ ...prevQuery, query: valueSearch }));
    }, [valueSearch]);

    return (
        <Table
            columns={columns}
            dataSource={mockCoupons}
            loading={isLoading}
            pagination={{
                current: Number(query.page),
                pageSize: Number(query.limit),
                total: (isMeta?.totalPage || 0) * Number(query.limit),
                onChange: (page, pageSize) => {
                    setQuery((prevQuery) => ({
                        ...prevQuery,
                        page: page,
                        limit: pageSize,
                    }));
                },
            }}
            rowKey="id"
        />
    );
}
