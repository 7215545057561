import http from '@/configs/http';
import { ENDPOINTCONSTANTS } from '@/constants/EndPointConstants';
import { Table, TableColumnsType } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { DataType, ItemCoupon, MetaType, QueryCouponPagination } from './types';
import Paragraph from 'antd/es/typography/Paragraph';

export default function AllCoupon() {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isListCoupon, setIsListCoupon] = React.useState<DataType[]>([]);
    const [isMeta, setIsMeta] = React.useState<MetaType>();

    const [query, setQuery] = React.useState<QueryCouponPagination>({
        type: 'invite',
    });

    const formatDate = (date: string) => {
        const dateObj = new Date(date);
        const hours = String(dateObj.getHours()).padStart(2, '0');
        const minutes = String(dateObj.getMinutes()).padStart(2, '0');
        const seconds = String(dateObj.getSeconds()).padStart(2, '0');
        const day = dateObj.getDate();
        const month = dateObj.getMonth() + 1;
        const year = dateObj.getFullYear();

        return `${hours}:${minutes}:${seconds} ${day}/${month}/${year}`;
    };
    const handleGetAllCoupon = useCallback(async () => {
        setIsLoading(true);
        const currentDate = new Date();
        try {
            const response = await http.get(ENDPOINTCONSTANTS.COUPON_REF, {
                params: query,
            });

            console.log(response);

            const newData: DataType[] = response.data.items.map((item: ItemCoupon) => {
                return {
                    key: item.id,
                    code: item?.couponHaravanCode,
                    creator: item.ownerName ? item.ownerName : '------',
                    status: item.used ? (
                        <p className="text-red-500">Đã sử dụng</p>
                    ) : // if end date < current date => expired
                    item.endDate && new Date(item.endDate) < currentDate ? (
                        <p className="text-red-500">Hết hạn</p>
                    ) : (
                        <p className="text-yellow-500">Chưa sử dụng</p>
                    ),
                    usedByName: item.usedByName ? item.usedByName : '------',
                    startTime: formatDate(item.startDate),
                    endTime: item.endDate ? formatDate(item.endDate) : '------',
                    numberOfUses: item.usedCount ? item.usedCount : 0,
                };
            });

            setIsListCoupon(newData);
            setIsMeta(response.data.meta);
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsLoading(false);
        }
    }, [query]);

    useEffect(() => {
        handleGetAllCoupon();

        const interval = setInterval(handleGetAllCoupon, 3000);

        return () => clearInterval(interval);
    }, [handleGetAllCoupon]);

    const columns: TableColumnsType<DataType> = [
        {
            title: 'Mã',
            dataIndex: 'code',
            render: (text: string) => <Paragraph copyable>{text.toLocaleUpperCase()}</Paragraph>,
        },
        {
            title: ' Người tạo',
            dataIndex: 'creator',
        },
        {
            title: 'Trạng thái sử dụng',
            dataIndex: 'status',
        },
        {
            title: 'Người sử dụng',
            dataIndex: 'usedByName',
        },
        {
            title: 'Thời gian bắt đầu',
            dataIndex: 'startTime',
        },
        {
            title: 'Thời gian kết thúc',
            dataIndex: 'endTime',
        },
        {
            title: 'Số lần sử dụng',
            dataIndex: 'numberOfUses',
        },
    ];

    // after 3s reload data + pagination

    return (
        <>
            <Table
                columns={columns}
                dataSource={isListCoupon}
                pagination={{
                    total: isMeta?.totalItems,
                    current: isMeta?.currentPage,
                    pageSize: isMeta?.itemsPerPage,
                    showSizeChanger: false,
                }}
                onChange={(pagination) => {
                    console.log('pagination', pagination);

                    setQuery({
                        ...query,
                        page: pagination.current,
                    });
                }}
            />
        </>
    );
}
